<template>
  <div>
    <!-- promo-video -->
    <section class="fdn-promo-video">
      <div class="container">
        <div class="fdn-promo-video-content">
          <div class="fdn-promo-video-content-text">
            <h2>EM QUALQUER LUGAR</h2>
            <p>O melhor conhecimento para empreendedores, famílias e entidades. Aqui, estudar dá dinheiro!</p>
            <div class="fdn-promo-video-content-text-action">
              <button
                type="button"
                class="btn btn-default"
              >
                <span data-play-promo-video>Assista o vídeo</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.329"
                  height="27.542"
                  viewBox="0 0 20.329 27.542"
                >
                  <path
                    id="play-button"
                    d="M25.546,13.231,6.528.115A.656.656,0,0,0,5.5.655V26.886a.656.656,0,0,0,1.028.54L25.546,14.31a.656.656,0,0,0,0-1.079Z"
                    transform="translate(-5.5)"
                    fill="#828282"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="fdn-promo-video-content-video">
            <!-- <vue-plyr>
              <div
                data-plyr-provider="youtube"
                data-plyr-embed-id="Aod0u1HL6fc"
              />
            </vue-plyr> -->
          </div>
        </div>
      </div>
    </section>

    <section class="fd-contacts mt-4">
      <div class="container">
        <div class="row justify-content-around">
          <div class="col-sm-12 col-md-12 col-lg-12">
            <div style="max-width: 500px; margin: auto">
              <div class="text-center mt-4">
                <h2>Como podemos lhe ajudar?</h2>
                <h3>Deixe seus dados que rapidamente entramos em contato</h3>
                <img
                  id="fd-contacts-header-form-effect"
                  v-lazy="require('@/assets/images/form-effect-01.png')"
                  class="img-fluid"
                >
              </div>
              <div class="mt-4">
                <div class="fd-cadastro-form">
                  <form
                    id="formcadastro"
                    role="form"
                    @submit.prevent="enviaContato()"
                  >
                    <div class="messages" />
                    <div class="form-group">
                      <label for="nome_completo">* Seu nome completo</label>
                      <input
                        id="nome_completo"
                        v-model="fastContato.nomeCompleto"
                        type="text"
                        class="form-control form-control-lg"
                        aria-describedby="nome_completoinfo"
                        placeholder="Como devemos chama-lo(a)?"
                        required="required"
                        data-error="Digite seu nome"
                      >
                      <small
                        id="nome_completoinfo"
                        class="form-text text-muted"
                      />
                      <div
                        class="help-block with-errors"
                        style="color:red"
                      />
                    </div>
                    <div class="form-group">
                      <label for="email">* Seu email de trabalho</label>
                      <input
                        id="email"
                        v-model="fastContato.email"
                        type="email"
                        class="form-control form-control-lg"
                        aria-describedby="emailinfo"
                        placeholder="Qual seu e-mail?"
                        required="required"
                        data-error="Digite um e-mail válido"
                      >
                      <small
                        id="emailinfo"
                        class="form-text text-muted"
                      />
                      <div
                        class="help-block with-errors"
                        style="color:red"
                      />
                    </div>
                    <div class="form-group">
                      <label for="telefone">* Seu telefone</label>
                      <input
                        id="telefone"
                        v-model="fastContato.telefone"
                        type="text"
                        class="form-control form-control-lg"
                        aria-describedby="telefoneinfo"
                        placeholder="Seu telefone?"
                        required="required"
                        data-error="Digite seu telefone"
                      >
                      <small
                        id="senhainfo"
                        class="form-text text-muted"
                      />
                      <div
                        class="help-block with-errors"
                        style="color:red"
                      />
                    </div>
                    <div class="form-group">
                      <label for="motivo">* Qual motivo do contato</label>
                      <textarea
                        id="motivo"
                        v-model="fastContato.motivo"
                        type="text"
                        class="form-control form-control-lg"
                        aria-describedby="motivoinfo"
                        placeholder="Motivo do contato?"
                        required="required"
                        data-error="Digite o motivo"
                      />
                      <small
                        id="motivoingo"
                        class="form-text text-muted"
                      />
                      <div
                        class="help-block with-errors"
                        style="color:red"
                      />
                    </div>
                    <div class="mt-5 text-center">
                      <input
                        id="Salvar"
                        type="submit"
                        class="btn btn-chat-online"
                        style="width:100%;font-size:larger"
                        value="Quero receber contato!"
                      >
                      <br>
                      <br>
                    </div>
                    <br>
                    <br>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-5 rd-contact" />
        </div>
      </div>
    </section>
    <!-- fd-footer -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
//import VuePlyr from "vue-plyr";

export default {
  name: "HomeExternoPrincipal",
  components: {
    //VuePlyr,
  },
  mixins: [methods],
  data: function () {
    return {
      fastContato: {
        nomeCompleto: "",
        email: "",
        telefone: "",
        motivo: "",
      },
    };
  },
  computed: {
    fastTelefoneContato() {
      return (
        settings.fastTelefoneContato.slice(0, 3) +
        " (" +
        settings.fastTelefoneContato.slice(3, 5) +
        ")" +
        " " +
        settings.fastTelefoneContato.slice(5)
      );
    },
  },
  mounted() {},
  methods: {
    enviaContato() {
      this.promisseEnviaContato()
        .then((e) => {
          console.log(e);
          this.$toast.open({
            message: "Contato enviado com sucesso",
            type: "success",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
          this.fastContato = {
            nomeCompleto: "",
            email: "",
            telefone: "",
            motivo: "",
          };
        })
        .catch((e) => {
          console.log(e);
          this.$toast.open({
            message: e,
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
        });
    },
    async promisseEnviaContato() {
      this.$toast.open({
        message: "Enviando...",
        type: "info",
        duration: 5000,
        dismissible: true,
        position: "bottom-right",
      });
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad +
              "api/fast_plataforma_usuario/enviaContatoEadiwinstar",
            this.fastAjaxOptions("POST", JSON.stringify(this.fastContato))
          );
          let json = await resp.json();
          let obj = JSON.parse(json);
          this.$toast.clear();
          if (obj.status) {
            resolve(obj);
          } else {
            reject(obj.error);
          }
        } catch (e) {
          this.$toast.clear();
          reject(e);
        }
      });
    },
  },
};
</script>

<style></style>
